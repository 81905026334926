import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import Loading from "../../../components/Loading/Loading";
import CookieBanner from "../../../components/CookieBanner/CookieBanner";
import MarketingNavigation from "../../../routing/MarketingNavigation/MarketingNavigation";
import axios from "axios";
import "./reporting-dash.scss";

const MONTH_LOADING_MESSAGE = "Loading...";
const IFRAME_ABOUT_BLANK = "about:blank";
const SELECT_FILTER_LABEL = "Month: ";
const MONTH_FILTER_LABEL = "Month";
const PAGE_ID_FILTER_LABEL = "PageId";
const LOOKER_DASH_SRC_DOMAIN = "https://beatdapp.cloud.looker.com";
const EMBED_DASH_PREFIX = "/embed/dashboards/";
const EMBED_DASH_PAGE_ID_REGEX = /\/embed\/dashboards\/(\d+)/;
const INTERNAL_VAMP_DASH_ID = "105";
const WELCOME_DASH_ID = "104";

const getPageIdFromParams = (paramArr) => {
  return paramArr?.find((param) => param.field === PAGE_ID_FILTER_LABEL)?.value;
};

const getDashIdFromUrlViaRegex = (url) => {
  return url?.match(EMBED_DASH_PAGE_ID_REGEX)?.[1];
};

const generateDynamicFilterArray = (dashId, dashSrcUrl) => {
  const dashParams = new URLSearchParams(dashSrcUrl.search);
  const paramsToHide = [MONTH_FILTER_LABEL, "embed_domain", "allow_login_screen"];
  const newDynamicFilterArray = [
    {
      field: PAGE_ID_FILTER_LABEL,
      value: dashId,
    },
  ];

  // Loop through params to find filters
  for (const param of dashParams) {
    const [filter, value] = param;
    if (!paramsToHide.includes(filter)) {
      // Add a search bar here in the future, potentially
      // For now, just add to URL
      newDynamicFilterArray.push({
        field: filter,
        value: value,
      });
    }

    // Append params to hide any and all filters
    // Temporarily disabled as we allow for the user to search for specific IDs/names
    // dashSrcUrl.searchParams.append("hide_filter", filter);

    return newDynamicFilterArray;
  }
};

const ReportingDash = () => {
  const history = useHistory();
  const location = useLocation();

  // Get month and other params from URL
  const queryParams = new URLSearchParams(location.search);
  const defaultLookerMonth = queryParams.get(MONTH_FILTER_LABEL);
  const defaultDashboard = queryParams.get(PAGE_ID_FILTER_LABEL) ?? "";
  const defaultFilterArray = [];

  for (const [key, value] of queryParams) {
    if (key !== MONTH_FILTER_LABEL) {
      defaultFilterArray.push({
        field: key,
        value: value,
      });
    }
  }

  if (defaultLookerMonth) {
    defaultFilterArray.push({
      field: MONTH_FILTER_LABEL,
      value: defaultLookerMonth,
    });
  }

  // Get default dashboard source based on URL params
  // Including a different dashboard ID altogether
  const defaultDashUrl = new URL(
    `${
      defaultDashboard
        ? `${LOOKER_DASH_SRC_DOMAIN}${EMBED_DASH_PREFIX}${defaultDashboard}`
        : LOOKER_DASH_SRC_DOMAIN
    }?allow_login_screen=true&hide_filter=Month&embed_domain=${window.location.origin}&Month=${
      defaultLookerMonth || ""
    }`
  );

  const defaultDashParams = new URLSearchParams(defaultDashUrl.search);

  for (const [key, value] of queryParams) {
    defaultDashParams.set(key, value);
  }
  defaultDashUrl.search = defaultDashParams.toString();

  // Set state based on URL params/defaults
  const [filterOptions, setFilterOptions] = useState(null);

  const [backButtonArray, setBackButtonArray] = useState([
    {
      url: defaultDashUrl?.toString(),
      params: defaultFilterArray,
      month: defaultLookerMonth,
    },
  ]);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filtersAreChanging, setFiltersAreChanging] = useState(false);
  const [usersDefaultLandingPage, setUsersDefaultLandingPage] = useState("");
  const [iframeKey, setIframeKey] = useState(Date.now());
  const [ranGetLookerMonths, setRanGetLookerMonths] = useState(false);
  const lookerIframeRef = useRef(null);

  // Update Looker dashboard source
  // And various dependent states
  const updateLookerDashSrc = (
    dashSrcFullOrPrefix,
    newFilterArr,
    backButtonClick = false,
    resetButtonClick = false
  ) => {
    if (dashSrcFullOrPrefix) {
      const newLookerDashSrc = new URL(dashSrcFullOrPrefix);
      const newMonth = resetButtonClick
        ? !filterOptions
          ? ""
          : filterOptions?.[0]?.field
        : newLookerDashSrc?.searchParams?.get(MONTH_FILTER_LABEL);

      if (resetButtonClick) {
        setBackButtonArray((prevBackButtonArray) => [
          ...prevBackButtonArray,
          {
            url: newLookerDashSrc.toString(),
            params: newFilterArr,
            month: newMonth,
          },
        ]);
        return;
      }

      // Update back button array
      const prevFinalItem = backButtonArray?.[backButtonArray?.length - 1];
      const prevFinalItemPageId = getPageIdFromParams(prevFinalItem?.params);

      if (
        (newMonth || prevFinalItemPageId === INTERNAL_VAMP_DASH_ID || !ranGetLookerMonths) &&
        newFilterArr &&
        newLookerDashSrc &&
        lookerIframeRef?.current?.src !== IFRAME_ABOUT_BLANK &&
        lookerIframeRef?.current?.src !== newLookerDashSrc &&
        (backButtonClick ||
          newMonth !== prevFinalItem?.month ||
          newLookerDashSrc.toString() !== prevFinalItem?.url ||
          newFilterArr?.length !== prevFinalItem?.params?.length)
      ) {
        setBackButtonArray((prevBackButtonArray) => {
          const prevFinalItem = prevBackButtonArray[prevBackButtonArray.length - 1];
          const slicedArray =
            backButtonClick ||
            ((!prevFinalItem?.month || prevFinalItem?.month === MONTH_LOADING_MESSAGE) &&
              prevFinalItemPageId !== INTERNAL_VAMP_DASH_ID)
              ? prevBackButtonArray.slice(0, -1)
              : prevBackButtonArray;
          return backButtonClick
            ? slicedArray
            : [
                ...slicedArray,
                {
                  url: newLookerDashSrc.toString(),
                  params: newFilterArr,
                  month: newMonth,
                },
              ];
        });
      }

      // Update dashboard state values
      setLoading(true);
    }
  };

  useEffect(() => {
    const updatePageUrlQueries = (filters, month) => {
      let params = new URLSearchParams();

      // Set default page ID first so it's present if not yet in the filters
      // And is always first in the URL
      const lookerDashSrc = backButtonArray[backButtonArray.length - 1]?.url;
      const defaultPageId =
        typeof lookerDashSrc === "string" ? getDashIdFromUrlViaRegex(lookerDashSrc) ?? "" : "";
      params.set(PAGE_ID_FILTER_LABEL, defaultPageId);

      const sortedFilters = [...filters]?.sort((a, b) => {
        if (a.field === PAGE_ID_FILTER_LABEL) return -1;
        if (b.field === PAGE_ID_FILTER_LABEL) return 1;
        if (a.field === MONTH_FILTER_LABEL) return 1;
        if (b.field === MONTH_FILTER_LABEL) return -1;
        return a.field.localeCompare(b.field);
      });

      for (const filter of sortedFilters) {
        const { field, value } = filter;
        params.set(field, value);
      }
      if (month && month !== MONTH_LOADING_MESSAGE) params.set(MONTH_FILTER_LABEL, month);

      const currentQueryParams = new URLSearchParams(location.search);

      if (currentQueryParams.toString() !== params.toString()) {
        history.replace({ search: params.toString() });
      }
    };

    // Update page URL queries
    const currentBackArrayItem = backButtonArray[backButtonArray?.length - 1];
    updatePageUrlQueries(currentBackArrayItem?.params, currentBackArrayItem?.month);

    // We don't want to update on history changing, since we're updating the history within the useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backButtonArray]);

  useEffect(() => {
    // Trigger on history replace event
    // To clear iframe history
    return history.listen((location, action) => {
      if (action === "REPLACE") {
        setIframeKey(Date.now());
      }
    });
  }, [history]);

  useEffect(() => {
    const getLookerMonths = async (monthlySummaryDashId) => {
      try {
        setRanGetLookerMonths(true);
        const res = await axios.post(
          process.env.REACT_APP_LOOKER_API_MONTH_ENDPOINT,
          {
            dash_id: `.${monthlySummaryDashId}.`,
          },
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        const newFilterOptions = JSON.parse(
          res?.data?.[0]?.["reporting_available_months_by_dash_id.available_dates"]
        )?.map((month) => {
          const trimmedMonth = month?.trim();
          return {
            field: trimmedMonth,
            label: trimmedMonth,
          };
        });

        const defaultFilterOption =
          newFilterOptions?.[0]?.field === MONTH_LOADING_MESSAGE
            ? ""
            : newFilterOptions?.[0]?.field;

        setFilterOptions(newFilterOptions);

        setBackButtonArray((prevBackButtonArray) => {
          const prevFinalItem = prevBackButtonArray[prevBackButtonArray.length - 1];
          const currentMonth = prevFinalItem?.month;
          // If no month is specified in the URL, use the default month
          if (!currentMonth || currentMonth === MONTH_LOADING_MESSAGE) {
            const prevUrlWithNewMonth = new URL(prevFinalItem.url);
            prevUrlWithNewMonth.searchParams.set(MONTH_FILTER_LABEL, defaultFilterOption);

            const newEntry = {
              url: prevUrlWithNewMonth.toString(),
              params: prevFinalItem.params,
              month: defaultFilterOption,
            };

            return !prevFinalItem.month || prevFinalItem.month === newEntry.month
              ? [...prevBackButtonArray.slice(0, -1), newEntry]
              : [...prevBackButtonArray, newEntry];
          } else {
            return prevBackButtonArray;
          }
        });
      } catch (error) {
        // Do nothing
      }
    };

    const handleDashboardMessage = (event) => {
      // Listen for messages from Looker dashboard
      if (event.origin === LOOKER_DASH_SRC_DOMAIN) {
        const eventData = JSON.parse(event.data);

        // If user just logged in, load in the select filter and query months
        // Will not be triggered by login page redirect
        if (eventData.type === "page:changed") {
          setLoading(true);
          // Check if the params in back button array have PageId
          // If not, add it
          const currentBackArrayItem = backButtonArray[backButtonArray?.length - 1];
          const currentBackArrayItemParams = currentBackArrayItem?.params;
          const currentBackArrayItemPageId = Array.isArray(currentBackArrayItemParams)
            ? getPageIdFromParams(currentBackArrayItemParams)
            : "";

          // Check if the url contains a custom/different landing dash
          const LandingDash = eventData.page.url.match(/&LandingDash=(\d+)/)?.[1];
          const defaultEmbedDashId = getDashIdFromUrlViaRegex(eventData.page.url);
          // Set the default landing page for the user
          const newUserDefaultPageId = LandingDash || defaultEmbedDashId;
          const prevDashId = getDashIdFromUrlViaRegex(
            backButtonArray?.[backButtonArray?.length - 2]?.url
          );

          if (
            !usersDefaultLandingPage &&
            ranGetLookerMonths &&
            newUserDefaultPageId !== INTERNAL_VAMP_DASH_ID &&
            newUserDefaultPageId !== WELCOME_DASH_ID &&
            (LandingDash || (prevDashId === INTERNAL_VAMP_DASH_ID && defaultEmbedDashId))
          ) {
            setUsersDefaultLandingPage(
              `${LOOKER_DASH_SRC_DOMAIN}${EMBED_DASH_PREFIX}${newUserDefaultPageId}?allow_login_screen=true&hide_filter=Month&embed_domain=${window.location.origin}`
            );
          }

          if (!currentBackArrayItemPageId) {
            const regex = new RegExp(
              `^${LOOKER_DASH_SRC_DOMAIN.replace(
                /[.*+\-?^${}()|[\]\\]/g,
                "\\$&"
              )}${EMBED_DASH_PREFIX}\\d+`
            );

            if (newUserDefaultPageId && !regex.test(currentBackArrayItem?.url)) {
              const newParams = currentBackArrayItemParams.filter(
                (obj) => obj.field !== PAGE_ID_FILTER_LABEL
              );
              newParams.push({
                field: PAGE_ID_FILTER_LABEL,
                value: newUserDefaultPageId,
              });

              const newUrl = new URL(eventData.page.absoluteUrl);
              newUrl.searchParams.set(MONTH_FILTER_LABEL, currentBackArrayItem?.month);
              newUrl.searchParams.delete("LandingDash");

              // Update the embedded dash id to ensure it matches the newUserDefaultPageId
              const actualUrl = newUrl
                .toString()
                .replace(/\/embed\/dashboards\/\d+/, `/embed/dashboards/${newUserDefaultPageId}`);

              setBackButtonArray((prevBackButtonArray) => [
                ...prevBackButtonArray.slice(0, -1),
                {
                  url: actualUrl,
                  params: newParams,
                  month: currentBackArrayItem?.month,
                },
              ]);
            }
          }
          if (userLoggedIn === false) {
            setUserLoggedIn(true);
          }
          return;
        } else if (eventData.type === "dashboard:filters:changed") {
          // Catch when user has begun editing filters
          if (filtersAreChanging === false) setFiltersAreChanging(true);
          return;
        } else if (eventData.type === "dashboard:run:start") {
          if (filtersAreChanging) {
            // Update the dashboard source with the new filters
            const dashSrcUrl = new URL(backButtonArray[backButtonArray?.length - 1]?.url);
            const newDynamicFilterArray = [];

            Object.entries(eventData.dashboard.dashboard_filters).forEach(
              ([filterName, filterValue]) => {
                dashSrcUrl.searchParams.set(filterName, filterValue);
                newDynamicFilterArray.push({
                  field: filterName,
                  value: filterValue,
                });
              }
            );
            updateLookerDashSrc(dashSrcUrl.toString(), newDynamicFilterArray);
            setFiltersAreChanging(false);
            return;
          }
        } else if (eventData.type === "dashboard:run:complete" && eventData.status === "complete") {
          setFiltersAreChanging(false);
          setLoading(false);
          return;
        } else if (eventData.type === "dashboard:loaded") {
          // End any tracking of changing filters
          setFiltersAreChanging(false);

          // Get dashboard id and src url
          const urlPath = eventData?.dashboard?.url;
          const dashSrcUrl = new URL(`${LOOKER_DASH_SRC_DOMAIN}${urlPath}`);
          const dashId = getDashIdFromUrlViaRegex(urlPath);
          if (dashId && dashId !== WELCOME_DASH_ID) {
            // Avoid recursive calls
            if (dashId !== getDashIdFromUrlViaRegex(lookerIframeRef?.current?.src)) {
              // Set the embed domain to the current domain
              dashSrcUrl.searchParams.set("embed_domain", window.location.origin);

              const newDynamicFilterArray = generateDynamicFilterArray(dashId, dashSrcUrl);

              // Hardcoded hide Month only for now
              dashSrcUrl.searchParams.set("hide_filter", MONTH_FILTER_LABEL);

              updateLookerDashSrc(dashSrcUrl.toString(), newDynamicFilterArray);
            }

            // If we haven't yet, get the available months for the dashboard
            // But only if the dashboard uses the month filter
            if (
              !ranGetLookerMonths &&
              filterOptions?.length === undefined &&
              eventData?.dashboard?.dashboard_filters?.Month !== undefined
            ) {
              getLookerMonths(dashId);
            }
          }
          return;
        }
      }
    };

    window.addEventListener("message", handleDashboardMessage);

    // Cleanup event listener
    return () => window.removeEventListener("message", handleDashboardMessage);

    // If we added BackButtonArray as a dependency, it would cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersDefaultLandingPage, userLoggedIn, filtersAreChanging, updateLookerDashSrc]);

  // Month SelectFilter handler
  const updateLookerMonth = (newMonth) => {
    if (newMonth === MONTH_LOADING_MESSAGE || !newMonth) return;
    const currentBackArrayItem = backButtonArray[backButtonArray?.length - 1];

    const newFiltersArr = currentBackArrayItem?.params?.filter(
      (param) => param.field !== MONTH_FILTER_LABEL
    );
    newFiltersArr.push({ field: MONTH_FILTER_LABEL, value: newMonth });

    // Grab the current URL and set the new month param
    const newDashSrcUrl = new URL(currentBackArrayItem?.url);
    newDashSrcUrl.searchParams.set(MONTH_FILTER_LABEL, newMonth);
    updateLookerDashSrc(newDashSrcUrl.toString(), newFiltersArr);
  };

  // Reset page on load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Revert back to default dashboard with original month selection
  const handleResetButtonClick = () => {
    const defaultLookerSrcUrl = new URL(
      usersDefaultLandingPage ? usersDefaultLandingPage : LOOKER_DASH_SRC_DOMAIN
    );

    const defaultParams = [];
    const defaultMonth = filterOptions?.[0]?.field;
    if (defaultMonth && defaultMonth !== MONTH_LOADING_MESSAGE) {
      defaultLookerSrcUrl.searchParams.set(MONTH_FILTER_LABEL, defaultMonth);
      defaultParams.push({ field: MONTH_FILTER_LABEL, value: defaultMonth });
    }

    const defaultLookerSrc = defaultLookerSrcUrl.toString();
    const currentBackArrayItem = backButtonArray[backButtonArray?.length - 1];
    if (
      defaultLookerSrc !== currentBackArrayItem.url &&
      IFRAME_ABOUT_BLANK !== currentBackArrayItem.url
    ) {
      updateLookerDashSrc(defaultLookerSrc, defaultParams, false, true);
    }
  };

  // Revert back to the previous dashboard/month selection
  const handleBackButtonClick = () => {
    if (!(backButtonArray?.length > 0)) return;
    const previousLocationInfo = backButtonArray[backButtonArray?.length - 1];
    updateLookerDashSrc(previousLocationInfo?.url, previousLocationInfo?.params, true);
  };

  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"center"}
      className={"reporting-dash-page"}
    >
      <CookieBanner
        type={"reportingDash"}
        disabled={loading || userLoggedIn}
        saveAcceptance={false}
      />
      <MarketingNavigation
        reporting={true}
        selectFilterProps={
          userLoggedIn &&
          ranGetLookerMonths &&
          getDashIdFromUrlViaRegex(backButtonArray[backButtonArray?.length - 1]?.url) !==
            INTERNAL_VAMP_DASH_ID
            ? {
                setSelectedField: (newMonth) => {
                  updateLookerMonth(newMonth);
                },
                selectedField:
                  backButtonArray?.[backButtonArray?.length - 1]?.month || MONTH_LOADING_MESSAGE,
                filterOptions: filterOptions
                  ? filterOptions
                  : [
                      {
                        field: MONTH_LOADING_MESSAGE,
                        label: MONTH_LOADING_MESSAGE,
                      },
                    ],
                label: SELECT_FILTER_LABEL,
                disabled: false,
                className: "reporting-dash-select-filter",
              }
            : null
        }
        reportingResetButtonProps={{
          onClick: () => handleResetButtonClick(),
        }}
      />
      {/* If we add filter text boxes, put them here */}
      <Grid className={"reporting-dash-banner"} item xs={12}>
        {loading && (
          <div className={"reporting-dash-loading-animation"}>
            <Loading />
          </div>
        )}
        <Button
          onClick={() => handleBackButtonClick()}
          className={`reporting-back-button ${
            backButtonArray?.length > 1 &&
            userLoggedIn &&
            (backButtonArray[backButtonArray.length - 2]?.month || !ranGetLookerMonths)
              ? "active"
              : "disabled"
          } ${
            usersDefaultLandingPage &&
            typeof backButtonArray[backButtonArray.length - 1]?.url === "string" &&
            backButtonArray[backButtonArray.length - 1].url.split("?")?.[0] ===
              usersDefaultLandingPage?.split("?")?.[0]
              ? "default"
              : "lowered"
          }`}
          variant={"contained"}
          type={"button"}
        >
          <ArrowBackIcon />
        </Button>
        <iframe
          key={iframeKey}
          ref={lookerIframeRef}
          className={"reporting-dash-iframe"}
          title={"reporting-dash"}
          src={
            // Use the url params-defined default dashboard
            // If no proper link in state or lacking permissions, show about:blank
            // Unless the pageID is the internal VAMP dashboard
            !backButtonArray[backButtonArray?.length - 1]?.url ||
            (userLoggedIn &&
              ranGetLookerMonths &&
              (!backButtonArray[backButtonArray?.length - 1]?.month ||
                backButtonArray[backButtonArray?.length - 1]?.month === MONTH_LOADING_MESSAGE) &&
              getDashIdFromUrlViaRegex(backButtonArray[backButtonArray?.length - 1]?.url) !==
                INTERNAL_VAMP_DASH_ID)
              ? IFRAME_ABOUT_BLANK
              : backButtonArray[backButtonArray?.length - 1]?.url
          }
          onLoad={() => setLoading(false)}
          width="100%"
          height="100%"
        />
      </Grid>
    </Grid>
  );
};

export default ReportingDash;
